// This file was generate from script, You should not commit or upload this file to public
// It was contain many system credential for build only
export const config = {
    production: false,
    app_name: 'COVID-19 Tracking Platform',
    app_version: 'v1.3.4-build20596',
    build_time: 'Tue Oct 12 08:08:30 UTC 2021',
    build_commit: 'db7f31c',
    google_api_key: 'AIzaSyCsvQ62z2GFWU9Uvk78ttKCJGDY2mZAMZs',
};
